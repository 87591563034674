import { Breadcrumb } from "../../../components/Breadcrumb";
import { Col, Row, ColHeader, ItemsContainer } from "./styles";
import { Button, message, Modal, Typography } from "antd";
import { FiPlus } from "react-icons/fi";
import { StructureItem } from "./StructureItem";
import { CommercialStructureProps } from "../../../entities/DTO";
import { useEffect, useState } from "react";
import CommercialStructureService from "../../../services/Company/CommercialStructure";
import { useAppSelector } from "../../../hooks";
import { Loading } from "../../../components/Loading";
import { Input } from "../Parameter/styles";

export function CommercialStructure() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const parameters = useAppSelector((state) => state.entities.parameter);

  const [level0Label, setLevel0Label] = useState<string | null | undefined>(
    null
  );
  const [level0List, setLevel0List] = useState<CommercialStructureProps[]>([]);
  const [isLevel0ListLoading, setLevel0ListLoading] = useState<boolean>(false);
  const [selectedLevel0, setSelectedLevel0] =
    useState<CommercialStructureProps | null>(null);
  const [level1Label, setLevel1Label] = useState<string | null | undefined>(
    null
  );
  const [level1List, setLevel1List] = useState<CommercialStructureProps[]>([]);
  const [isLevel1ListLoading, setLevel1ListLoading] = useState<boolean>(false);
  const [selectedLevel1, setSelectedLevel1] =
    useState<CommercialStructureProps | null>(null);

  const [level2Label, setLevel2Label] = useState<string | null | undefined>(
    null
  );
  const [level2List, setLevel2List] = useState<CommercialStructureProps[]>([]);
  const [isLevel2ListLoading, setLevel2ListLoading] = useState<boolean>(false);
  const [selectedLevel2, setSelectedLevel2] =
    useState<CommercialStructureProps | null>(null);

  const [level3Label, setLevel3Label] = useState<string | null | undefined>(
    null
  );
  const [level3List, setLevel3List] = useState<CommercialStructureProps[]>([]);
  const [isLevel3ListLoading, setLevel3ListLoading] = useState<boolean>(false);
  const [selectedLevel3, setSelectedLevel3] =
    useState<CommercialStructureProps | null>(null);

  const [level4Label, setLevel4Label] = useState<string | null | undefined>(
    null
  );
  const [level4List, setLevel4List] = useState<CommercialStructureProps[]>([]);
  const [isLevel4ListLoading, setLevel4ListLoading] = useState<boolean>(false);
  const [selectedLevel4, setSelectedLevel4] =
    useState<CommercialStructureProps | null>(null);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const [level0Name, setlevel0Name] = useState<string>("");
  const [level0Id, setlevel0Id] = useState<number | null | undefined>(null);

  const [level1Name, setlevel1Name] = useState<string>("");
  const [level1Id, setlevel1Id] = useState<number | null | undefined>(null);

  const [level2Name, setlevel2Name] = useState<string>("");
  const [level2Id, setlevel2Id] = useState<number | null | undefined>(null);

  const [level3Name, setlevel3Name] = useState<string | undefined>("");
  const [level3Id, setlevel3Id] = useState<number | null | undefined>(null);

  const [level4Name, setlevel4Name] = useState<string | undefined>("");

  async function handleClickLevel0(level0: CommercialStructureProps) {
    setLevel1List([]);
    setSelectedLevel1(null);
    setLevel2List([]);
    setSelectedLevel2(null);
    setLevel3List([]);
    setSelectedLevel3(null);
    setLevel4List([]);
    setSelectedLevel4(null);

    if (selectedLevel0?.id === level0.id) {
      setSelectedLevel0(null);
    } else {
      setSelectedLevel0(level0);
      await getLevel1List(level0.id);
    }
  }

  async function handleClickLevel1(level1: CommercialStructureProps) {
    setLevel2List([]);
    setSelectedLevel2(null);
    setLevel3List([]);
    setSelectedLevel3(null);
    setLevel4List([]);
    setSelectedLevel4(null);

    if (selectedLevel1?.id === level1.id) {
      setSelectedLevel1(null);
    } else {
      setSelectedLevel1(level1);
      await getLevel2List(level1.id);
    }
  }

  async function handleClickLevel2(level2: CommercialStructureProps) {
    setLevel3List([]);
    setSelectedLevel3(null);
    setLevel4List([]);
    setSelectedLevel4(null);

    if (selectedLevel2?.id === level2.id) {
      setSelectedLevel2(null);
    } else {
      setSelectedLevel2(level2);
      await getLevel3List(level2.id);
    }
  }

  async function handleClickLevel3(level3: CommercialStructureProps) {
    setLevel4List([]);
    setSelectedLevel4(null);

    if (selectedLevel3?.id === level3.id) {
      setSelectedLevel3(null);
    } else {
      setSelectedLevel3(level3);
      await getLevel4List(level3.id);
    }
  }

  async function handleClickLevel4(level4: CommercialStructureProps) {
    if (selectedLevel4?.id === level4.id) {
      setSelectedLevel4(null);
    } else {
      setSelectedLevel4(level4);
    }
  }

  async function getLevel0List() {
    try {
      setLevel0ListLoading(true);
      const response: CommercialStructureProps[] =
        await CommercialStructureService.getCompleteCommercialStrucuteLevel0(
          companyId
        );
      setLevel0List([...response]);
    } catch (e) {
      console.error("Falha ao buscar lista de level 0");
      console.error(e);
      message.error("Falha ao buscar lista de presidentes");
    } finally {
      setLevel0ListLoading(false);
    }
  }

  async function getLevel1List(level0Id: number) {
    try {
      setLevel1ListLoading(true);
      const response: CommercialStructureProps[] =
        await CommercialStructureService.getCompleteCommercialStrucuteLevel1(
          companyId,
          level0Id
        );
      setLevel1List([...response]);
    } catch (e) {
      console.error("Falha ao buscar lista de level 2");
      console.error(e);
      message.error("Falha ao buscar lista de Diretorias");
    } finally {
      setLevel1ListLoading(false);
    }
  }

  async function getLevel2List(level1Id: number) {
    try {
      setLevel2ListLoading(true);
      const response: CommercialStructureProps[] =
        await CommercialStructureService.getCompleteCommercialStrucuteLevel2(
          companyId,
          level1Id
        );
      setLevel2List([...response]);
    } catch (e) {
      console.error("Falha ao buscar lista de level 2");
      console.error(e);
      message.error("Falha ao buscar lista de Regionais");
    } finally {
      setLevel2ListLoading(false);
    }
  }

  async function getLevel3List(level2Id: number) {
    try {
      setLevel3ListLoading(true);
      const response: CommercialStructureProps[] =
        await CommercialStructureService.getCompleteCommercialStrucuteLevel3(
          companyId,
          level2Id
        );
      setLevel3List([...response]);
    } catch (e) {
      console.error("Falha ao buscar lista de level 3");
      console.error(e);
      message.error("Falha ao buscar lista de Filiais");
    } finally {
      setLevel3ListLoading(false);
    }
  }

  async function getLevel4List(level4Id: number) {
    try {
      setLevel4ListLoading(true);
      const response: CommercialStructureProps[] =
        await CommercialStructureService.getCompleteCommercialStrucuteLevel4(
          companyId,
          level4Id
        );
      setLevel4List([...response]);
    } catch (e) {
      console.error("Falha ao buscar lista de level 4");
      console.error(e);
      message.error("Falha ao buscar lista de Filiais");
    } finally {
      setLevel4ListLoading(false);
    }
  }

  useEffect(() => {
    getLevel0List();
  }, [companyId]);

  useEffect(() => {
    setLevel0Label(
      parameters.find((value) => value.name === "LEVEL0_LABEL")?.value
    );
    setLevel1Label(
      parameters.find((value) => value.name === "LEVEL1_LABEL")?.value
    );
    setLevel2Label(
      parameters.find((value) => value.name === "LEVEL2_LABEL")?.value
    );
    setLevel3Label(
      parameters.find((value) => value.name === "LEVEL3_LABEL")?.value
    );
    setLevel4Label(
      parameters.find((value) => value.name === "LEVEL4_LABEL")?.value
    );
  }, [parameters]);

  function renderLevel0List() {
    if (isLevel0ListLoading) {
      return <Loading />;
    }

    return level0List.map((item, i) => (
      <StructureItem
        isActive={selectedLevel0?.id === item.id}
        handleClick={() => handleClickLevel0(item)}
        key={i}
        structure={item}
        level={0}
        handleUpdate={() => handleUpdate(item)}
        showDelete={
          selectedLevel0?.id === item.id &&
          level0List.length === 0 &&
          !isLevel0ListLoading
        }
        handleDelete={async () => await handleDelete(item.id, 0)}
      />
    ));
  }

  function renderLevel1List() {
    if (isLevel1ListLoading) {
      return <Loading />;
    }

    return level1List.map((item, i) => (
      <StructureItem
        isActive={selectedLevel1?.id === item.id}
        handleClick={() => handleClickLevel1(item)}
        key={i}
        structure={item}
        level={1}
        handleUpdate={() => handleUpdate(item)}
        showDelete={
          selectedLevel1?.id === item.id &&
          level2List.length === 0 &&
          !isLevel2ListLoading
        }
        handleDelete={async () => await handleDelete(item.id, 1)}
      />
    ));
  }

  function renderLevel2List() {
    if (isLevel2ListLoading) {
      return <Loading />;
    }

    return level2List.map((item, i) => (
      <StructureItem
        isActive={selectedLevel2?.id === item.id}
        handleClick={() => handleClickLevel2(item)}
        key={i}
        structure={item}
        level={2}
        handleUpdate={() => handleUpdate(item)}
        showDelete={
          selectedLevel2?.id === item.id &&
          level3List.length === 0 &&
          !isLevel3ListLoading
        }
        handleDelete={async () => await handleDelete(item.id, 2)}
      />
    ));
  }

  function renderLevel3List() {
    if (isLevel3ListLoading) {
      return <Loading />;
    }

    return level3List.map((item, i) => (
      <StructureItem
        isActive={selectedLevel3?.id === item.id}
        handleClick={() => handleClickLevel3(item)}
        key={i}
        structure={item}
        level={3}
        handleUpdate={() => handleUpdate(item)}
        showDelete={
          selectedLevel3?.id === item.id &&
          level4List.length === 0 &&
          !isLevel4ListLoading
        }
        handleDelete={async () => await handleDelete(item.id, 3)}
      />
    ));
  }

  function renderLevel4List() {
    if (isLevel4ListLoading) {
      return <Loading />;
    }

    return level4List.map((item, i) => (
      <StructureItem
        isActive={selectedLevel4?.id === item.id}
        handleClick={() => handleClickLevel4(item)}
        key={i}
        structure={item}
        level={4}
        handleUpdate={() => handleUpdate(item)}
        showDelete={true}
        handleDelete={async () => await handleDelete(item.id, 4)}
      />
    ));
  }

  function handleUpdate(item: CommercialStructureProps) {
    setSelectedId(item.id);
    setSelectedLevel(Number(item.level_type));

    setlevel0Id(item.level0_id);
    setlevel0Name(item.level0_external_name);

    setlevel1Id(item.level1_id);
    setlevel1Name(item.level1_external_name ?? "");

    setlevel2Id(item.level2_id);
    setlevel2Name(item.level2_external_name ?? "");

    setlevel3Id(item.level3_id);
    setlevel3Name(item.level3_external_name ?? "");

    setlevel4Name(item.level4_external_name ?? "");

    setShowModal(true);
  }

  async function handleDelete(id: number, level: number) {
    try {
      await CommercialStructureService.deleteCommercialStrucuteItem(
        companyId,
        id
      );

      switch (level) {
        case 1:
          setSelectedLevel1(null);
          await getLevel1List(selectedLevel0?.id ?? 0);
          break;
        case 2:
          await getLevel2List(selectedLevel1?.id ?? 0);
          setSelectedLevel2(null);
          break;
        case 3:
          await getLevel3List(selectedLevel2?.id ?? 0);
          setSelectedLevel3(null);
          break;
        case 4:
          await getLevel4List(selectedLevel3?.id ?? 0);
          setSelectedLevel4(null);
          break;
        default:
          await getLevel0List();
          setSelectedLevel0(null);
          break;
      }
    } catch (e) {
      console.error("Falha ao salvar item");
      console.error(e);
    }
  }

  async function handleSave() {
    try {
      if (selectedId) {
        await CommercialStructureService.updateCommercialStrucuteItem(
          companyId,
          selectedId,
          buildItemBody()
        );
      } else {
        await CommercialStructureService.createCommercialStrucuteItem(
          companyId,
          buildItemBody()
        );
      }
      setShowModal(false);
      clearData();

      switch (selectedLevel) {
        case 1:
          await getLevel1List(selectedLevel0?.id ?? 0);
          break;
        case 2:
          await getLevel2List(selectedLevel1?.id ?? 0);
          break;
        case 3:
          await getLevel3List(selectedLevel2?.id ?? 0);
          break;
        case 4:
          await getLevel4List(selectedLevel3?.id ?? 0);
          break;
        default:
          await getLevel0List();
          break;
      }
    } catch (e) {
      console.error("Falha ao salvar item");
      console.error(e);
    }
  }

  function buildItemBody(): any {
    return {
      company_id: companyId,
      level0_id: level0Id,
      level0_external_name: level0Name,
      level1_id: level1Id,
      level1_external_name: level1Name ? level1Name : null,
      level2_id: level2Id,
      level2_external_name: level2Name ? level2Name : null,
      level3_id: level3Id,
      level3_external_name: level3Name ? level3Name : null,
      level4_external_name: level4Name ? level4Name : null,
      level_type: selectedLevel.toString(),
      structure_type: "DISTRIBUIDOR",
    };
  }

  function handleCancel() {
    setShowModal(false);
    clearData();
  }

  function handleOpenNewModal(level: number) {
    setSelectedLevel(level);
    setShowModal(true);

    if (level === 1) {
      setlevel0Id(selectedLevel0?.id ?? null);
      setlevel0Name(selectedLevel0?.level0_external_name ?? "");
    } else if (level === 2) {
      setlevel0Id(selectedLevel1?.level0_id ?? null);
      setlevel0Name(selectedLevel1?.level0_external_name ?? "");

      setlevel1Id(selectedLevel1?.id ?? null);
      setlevel1Name(selectedLevel1?.level1_external_name ?? "");
    } else if (level === 3) {
      setlevel0Id(selectedLevel2?.level0_id ?? null);
      setlevel0Name(selectedLevel2?.level0_external_name ?? "");

      setlevel1Id(selectedLevel2?.level1_id ?? null);
      setlevel1Name(selectedLevel2?.level1_external_name ?? "");

      setlevel2Id(selectedLevel2?.id ?? null);
      setlevel2Name(selectedLevel2?.level2_external_name ?? "");
    } else if (level === 4) {
      setlevel0Id(selectedLevel2?.level0_id ?? null);
      setlevel0Name(selectedLevel2?.level0_external_name ?? "");

      setlevel1Id(selectedLevel2?.level1_id ?? null);
      setlevel1Name(selectedLevel2?.level1_external_name ?? "");

      setlevel2Id(selectedLevel2?.id ?? null);
      setlevel2Name(selectedLevel2?.level2_external_name ?? "");

      setlevel3Id(selectedLevel3?.id ?? null);
      setlevel3Name(selectedLevel3?.level3_external_name ?? "");
    }
  }

  function clearData() {
    setSelectedId(null);
    setSelectedLevel(0);
    setlevel0Name("");
    setlevel1Name("");
    setlevel2Name("");
    setlevel3Name("");
    setlevel4Name("");
  }

  function getModalName() {
    switch (selectedLevel) {
      case 0:
        return level0Label;
      case 1:
        return level1Label;
      case 2:
        return level2Label;
      case 3:
        return level3Label;
      default:
        return level4Label;
    }
  }

  function disableSave() {
    switch (selectedLevel) {
      case 0:
        return !level0Name;
      case 1:
        return !level1Name;
      case 2:
        return !level2Name;
      case 3:
        return !level3Name;
      default:
        return !level4Name;
    }
  }

  return (
    <Breadcrumb path="Estrutura Comercial" child={["Estrutura Comercial"]}>
      <>
        <Row>
          <Col>
            <ColHeader>
              <span>{level0Label}</span>
              {level0Label != null ? (
                <></>
              ) : (
                <Button
                  onClick={() => handleOpenNewModal(0)}
                  type="primary"
                  icon={<FiPlus />}
                >
                  Novo
                </Button>
              )}
            </ColHeader>

            <ItemsContainer>{renderLevel0List()}</ItemsContainer>
          </Col>

          <Col>
            <ColHeader>
              <span>{level1Label}</span>
              <Button
                onClick={() => handleOpenNewModal(1)}
                disabled={!selectedLevel0}
                type="primary"
                icon={<FiPlus />}
              >
                Novo
              </Button>
            </ColHeader>

            <ItemsContainer>{renderLevel1List()}</ItemsContainer>
          </Col>

          <Col>
            <ColHeader>
              <span>{level2Label}</span>
              <Button
                onClick={() => handleOpenNewModal(2)}
                disabled={!selectedLevel1}
                type="primary"
                icon={<FiPlus />}
              >
                Novo
              </Button>
            </ColHeader>

            <ItemsContainer>{renderLevel2List()}</ItemsContainer>
          </Col>

          <Col>
            <ColHeader>
              <span>{level3Label}</span>
              <Button
                onClick={() => handleOpenNewModal(3)}
                disabled={!selectedLevel2}
                type="primary"
                icon={<FiPlus />}
              >
                Novo
              </Button>
            </ColHeader>

            <ItemsContainer>{renderLevel3List()}</ItemsContainer>
          </Col>

          <Col>
            <ColHeader>
              <span>{level4Label}</span>
              <Button
                onClick={() => handleOpenNewModal(4)}
                disabled={!selectedLevel3}
                type="primary"
                icon={<FiPlus />}
              >
                Novo
              </Button>
            </ColHeader>

            <ItemsContainer>{renderLevel4List()}</ItemsContainer>
          </Col>
        </Row>

        <Modal
          visible={showModal}
          okText={"Salvar"}
          onOk={handleSave}
          okButtonProps={{ disabled: disableSave() }}
          onCancel={handleCancel}
        >
          <Typography.Title level={4}>
            Estrutura comercial ({getModalName()})
          </Typography.Title>
          <Input
            disabled={selectedLevel !== 0}
            size="large"
            placeholder="Nome Presidente"
            value={level0Name}
            onChange={(e) => setlevel0Name(e.target.value)}
          />
          <Input
            disabled={selectedLevel !== 1}
            size="large"
            placeholder="Nome Diretoria"
            value={level1Name}
            onChange={(e) => setlevel1Name(e.target.value)}
          />
          <Input
            disabled={selectedLevel !== 2}
            size="large"
            placeholder="Nome Regional"
            value={level2Name}
            onChange={(e) => setlevel2Name(e.target.value)}
          />
          <Input
            disabled={selectedLevel !== 3}
            size="large"
            placeholder="Nome Filial"
            value={level3Name}
            onChange={(e) => setlevel3Name(e.target.value)}
          />
          <Input
            disabled={selectedLevel !== 4}
            size="large"
            placeholder="Nome Vendedor"
            value={level4Name}
            onChange={(e) => setlevel4Name(e.target.value)}
          />
          
        </Modal>
      </>
    </Breadcrumb>
  );
}
