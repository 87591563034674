import { Breadcrumb } from "../../../components/Breadcrumb";
import {
  Button,
  Col,
  Empty,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import { UserData } from "../../../services/Global/User/userDataTypes";
import CompanyCommercialStructureService from "../../../services/Company/CommercialStructure";
import CommercialStructureService from "../../../services/Company/CommercialStructure";
import { FiEdit2, FiFilter, FiPlus, FiSearch, FiTrash } from "react-icons/fi";
import React, { useCallback, useEffect, useState } from "react";
import UserService from "../../../services/Global/User";
import { Input } from "../Parameter/styles";
import { useAppSelector } from "../../../hooks";
import { Actions } from "./styles";
interface InitiativeProps {
  id: number;
  name: string;
  level4_id?: number;
  commercial_structure: any[];
  product_group_name: string;
  comercial_structure: any[];
  level4_external_name: any[];
}

interface TasksProps {
  commercial_structure: string | any;
  id: number;
  name: string;
  type_name: number;
  industry_name: string;
  product_group_name: string;
  company_id: number;
  industry_id: number;
  group_type_name: string;
  comercial_structure: any;
  group_price_base_line: number;
  business_type_id: number;
  updatedAt: number;
  createdAt: number;
}
const { Option } = Select;
export function CommercialStructureUser() {
  const idCompany = useAppSelector((state) => state.parameters.company.id);
  const [level0Label, setLevel0Label] = useState<string | null | undefined>(
    null
  );
  const [level1Label, setLevel1Label] = useState<string | null | undefined>(
    null
  );
  const [level2Label, setLevel2Label] = useState<string | null | undefined>(
    null
  );
  const [level3Label, setLevel3Label] = useState<string | null | undefined>(
    null
  );
  const [level4Label, setLevel4Label] = useState<string | null | undefined>(
    null
  );
  const [modalNewStructure, setModalNewStructure] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const [level0, setLevel0] = useState<number | undefined>();
  const [level1, setLevel1] = useState<number | undefined | string>();
  const [level2, setLevel2] = useState<number | undefined>();
  const [level3, setLevel3] = useState<number | undefined>();
  const [level4, setLevel4] = useState<number | undefined>();
  const parameters = useAppSelector((state) => state.entities.parameter);
  const [listUsers, setListUsers] = useState<UserData[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLoading2, setLoading2] = useState<boolean>(false);
  const [textError, setTextError] = useState("");
  const [modalError, setModalError] = useState(false);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const { name } = useAppSelector((state) => state.auth.user);
  const [nameSelectUser, setNameSelectUser] = useState<string>("");
  const [searchCompanyData, setSearchCompanyData] = useState<any>([]);
  const [searchCompanyData2, setSearchCompanyData2] = useState<any>([]);
  const [searchUser, setSearchUser] = useState<string>("");
  const [searchUserData, setSearchUserData] = useState<any>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | undefined>(
    undefined
  );
  const [mesuDados, setMesuDados] = useState<any>([]);
  const [list, setList] = useState<any[]>([]);
  const [index, setIndex] = useState<number | undefined>();
  const [listLoading, setListLoading] = useState<boolean>(false);
  const [result, setResult] = useState<number | undefined>();
  const [meuUser, setMeuUser] = useState<boolean>(false);
  const [enable1, setEnable1] = useState<boolean>(false);
  const [enable2, setEnable2] = useState<boolean>(false);
  const [enable3, setEnable3] = useState<boolean>(false);
  const [enable4, setEnable4] = useState<boolean>(false);
  const [enable5, setEnable5] = useState<boolean>(false);

  async function getMeusUser() {
    const response = await UserService.getCompanyLinks(companyId);
    for (let i = 0; i < response.length; i++) {
      if (response[i].user.name === name) {
        return setMesuDados(response[i]);
      }
    }
  }

  async function getCommercialStructureId() {
    setLoading2(true);
    try {
      setSearchCompanyData(undefined);
      const response: any[] =
        await CompanyCommercialStructureService.getAllCommercialStrucuteUser(
          companyId,
          selectedUserId
        );
      var data: any[] = [];
      response.map((_, item) => {
        _.company_id === companyId && data.push(_);
      });
      setSearchCompanyData(data);
      setSearchCompanyData2(data.map((_, item) => _.commercial_structure));
      if (name === nameSelectUser) {
        setMeuUser(true);
      } else {
        setMeuUser(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading2(false);
    }
  }

  async function getLevel(user_id: number | undefined) {
    try {
      setListLoading(true);

      const response: InitiativeProps[] =
        await CommercialStructureService.getCompleteCommercialStrucuteId(
          companyId,
          user_id
        );
      setList([...response]);
    } catch (e) {
      console.error("Falha ao buscar lista");
      console.error(e);
      message.error("Falha ao buscar lista");
    } finally {
      setListLoading(false);
    }
  }

  useEffect(() => {
    setLevel0Label(
      parameters.find((value) => value.name === "LEVEL0_LABEL")?.value
    );
    setLevel1Label(
      parameters.find((value) => value.name === "LEVEL1_LABEL")?.value
    );
    setLevel2Label(
      parameters.find((value) => value.name === "LEVEL2_LABEL")?.value
    );
    setLevel3Label(
      parameters.find((value) => value.name === "LEVEL3_LABEL")?.value
    );
    setLevel4Label(
      parameters.find((value) => value.name === "LEVEL4_LABEL")?.value
    );
  }, [parameters]);

  useEffect(() => {
    getMeusUser();
    getListUsers();
  }, []);
  useEffect(() => {
    getCommercialStructureId();
  }, [selectedUserId]);

  async function getListUsers() {
    try {
      setLoading(true);
      const response: UserData[] =
        await UserService.getCompanyUserWithGlobalUser(idCompany);
      setListUsers(response);
      setSearchUserData(response);
      setSearchUser("");
    } catch (e) {
      console.error("Falha ao buscar parâmetros: ");
      console.error(e);
    } finally {
      setLoading(false);
    }
  }
  function handleClickEdit(task: TasksProps, index: any) {
    mesuDados.profile_id === 1 &&
    task?.commercial_structure.level4_external_name != null
      ? setLevel1(
          task?.commercial_structure.level0_external_name +
            " - " +
            task?.commercial_structure.level1_external_name +
            " - " +
            task?.commercial_structure.level2_external_name +
            " - " +
            task?.commercial_structure.level3_external_name +
            " - " +
            task?.commercial_structure.level4_external_name
        )
      : mesuDados.profile_id === 1 &&
        task?.commercial_structure.level3_external_name != null
      ? setLevel1(
          task?.commercial_structure.level0_external_name +
            " - " +
            task?.commercial_structure.level1_external_name +
            " - " +
            task?.commercial_structure.level2_external_name +
            " - " +
            task?.commercial_structure.level3_external_name
        )
      : mesuDados.profile_id === 1 &&
        task?.commercial_structure.level2_external_name != null
      ? setLevel1(
          task?.commercial_structure.level0_external_name +
            " - " +
            task?.commercial_structure.level1_external_name +
            " - " +
            task?.commercial_structure.level2_external_name
        )
      : mesuDados.profile_id === 1 &&
        task?.commercial_structure.level1_external_name != null
      ? setLevel1(
          task?.commercial_structure.level0_external_name +
            " - " +
            task?.commercial_structure.level1_external_name
        )
      : mesuDados.profile_id === 2 &&
        task?.commercial_structure.level4_external_name != null
      ? setLevel1(
          task?.commercial_structure.level1_external_name +
            " - " +
            task?.commercial_structure.level2_external_name +
            " - " +
            task?.commercial_structure.level3_external_name +
            " - " +
            task?.commercial_structure.level4_external_name
        )
      : mesuDados.profile_id === 2 &&
        task?.commercial_structure.level3_external_name != null
      ? setLevel1(
          task?.commercial_structure.level1_external_name +
            " - " +
            task?.commercial_structure.level2_external_name +
            " - " +
            task?.commercial_structure.level3_external_name
        )
      : mesuDados.profile_id === 2 &&
        task?.commercial_structure.level2_external_name != null
      ? setLevel1(
          task?.commercial_structure.level1_external_name +
            " - " +
            task?.commercial_structure.level2_external_name
        )
      : mesuDados.profile_id === 2 &&
        task?.commercial_structure.level1_external_name != null
      ? setLevel1(task?.commercial_structure.level1_external_name)
      : mesuDados.profile_id === 3 &&
        task?.commercial_structure.level3_external_name != null
      ? setLevel1(
          task?.commercial_structure.level2_external_name +
            " - " +
            task?.commercial_structure.level3_external_name
        )
      : mesuDados.profile_id === 3 &&
        task?.commercial_structure.level2_external_name != null
      ? setLevel1(task?.commercial_structure.level2_external_name)
      : mesuDados.profile_id === 4 &&
        task?.commercial_structure.level4_external_name != null
      ? setLevel1(
          task?.commercial_structure.level3_external_name +
            " - " +
            task?.commercial_structure.level4_external_name
        )
      : mesuDados.profile_id === 4 &&
        task?.commercial_structure.level3_external_name != null
      ? setLevel1(task?.commercial_structure.level3_external_name)
      : setLevel1(task?.commercial_structure.level4_external_name);

    setSelectedTaskId(task.id);
    setResult(task.id);
    setIndex(task.id);
    setModalNewStructure(true);
  }
  async function confirmDelete(task: TasksProps) {
    try {
      await CompanyCommercialStructureService.deleteCommercialStrucuteUser(
        companyId,
        task.id
      );
    } catch (e) {
      console.error("Falha ao excluir: ");
      setModalError(true);
      setTextError(e.message);
      console.error(e);
    }
    await getListUsers();
    getCommercialStructureId();
  }

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];
  var c1 = {
    title: level0Label,
    dataIndex: "level0_external_name",
    key: "level0_external_name",
  };
  var c2 = {
    title: level1Label,
    dataIndex: "level1_external_name",
    key: "level1_external_name",
  };
  var c3 = {
    title: level2Label,
    dataIndex: "level2_external_name",
    key: "level2_external_name",
  };
  var c4 = {
    title: level3Label,
    dataIndex: "level3_external_name",
    key: "level3_external_name",
  };
  var c5 = {
    title: level4Label,
    dataIndex: "level4_external_name",
    key: "level4_external_name",
  };
  var c6 = {
    title: "Ações",
    dataIndex: "uf",
    key: "uf",
    width: 150,
    align: "center" as const,
    render: (_: null, record: TasksProps, index: number) => {
      return (
        <Actions>
          <FiEdit2
            size={18}
            color="#00CC66"
            onClick={() => handleClickEdit(searchCompanyData[index], record.id)}
          />
          <Popconfirm
            title="Tem certeza que deseja excluir?"
            onConfirm={() => confirmDelete(searchCompanyData[index])}
            okText="Excluir"
            okButtonProps={{ danger: true }}
            cancelText="Cancelar"
          >
            <FiTrash size={18} color="#00CC66" />
          </Popconfirm>
        </Actions>
      );
    },
  };
  const columns2 =
    mesuDados.profile_id === 1
      ? [c1, c2, c3, c4, c5, c6]
      : mesuDados.profile_id === 2
      ? [c2, c3, c4, c5, c6]
      : mesuDados.profile_id === 3
      ? [c3, c4, c5, c6]
      : mesuDados.profile_id === 4
      ? [c4, c5, c6]
      : [c5, c6];

  function searchTable(value: string) {
    if (value) {
      setSearchUser(value);
      const array = listUsers.filter(
        (record) =>
          record["name"].toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setSearchUserData(array);
    } else {
      setSearchUser("");
      setSearchUserData(listUsers);
    }
  }

  const userRowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: UserData[]) => {
      setSelectedUserId(selectedRows[0].id);
      setNameSelectUser(selectedRows[0].name);
      getLevel(selectedRows[0].id);
    },
    getCheckboxProps: (record: UserData) => ({
      name: record.name,
    }),
  };
  function showModalNewStructure() {
    setModalNewStructure(true);
  }
  async function handleOk() {
    setModalNewStructure(false);
    if (selectedTaskId) {
      try {
        await CompanyCommercialStructureService.updateCommercialStrucuteUser(
          companyId,
          selectedTaskId,
          {
            level4_id: [level1],
          }
        );
      } catch (e) {
        console.log("Falha ao atualizar");
        console.error(e);
      }
    } else {
      try {
        await CompanyCommercialStructureService.createCommercialStrucuteUser(
          companyId,
          {
            level4_id: [level1],
            user_id: selectedUserId,
          }
        );
      } catch (e) {
        console.log("Falha ao criar");
        console.error(e);
      }
    }
    await getListUsers();
    getCommercialStructureId();
    handleCancel();
  }

  function handleCancel() {
    setSelectedTaskId(null);
    setModalNewStructure(false);
    setResult(undefined);
    setLevel0(undefined);
    setLevel1(undefined);
    setLevel2(undefined);
    setLevel3(undefined);
    setLevel4(undefined);
  }

  return (
    <Breadcrumb path="Estrutura Comercial Usuário" child={["Estrutura Comercial Usuário"]}>
      <Row gutter={24}>
        <Col span={8}>
          <Row style={{ marginBottom: 20 }}>
            <Col span={24}>
              <Input
                allowClear
                value={searchUser}
                onChange={(e) => searchTable(e.target.value)}
                placeholder="Pesquisar"
                prefix={<FiSearch color="#00CC66" />}
                suffix={<FiFilter color="#00CC66" />}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Table
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={searchUserData}
                loading={isLoading}
                rowSelection={{
                  type: "radio",
                  ...userRowSelection,
                }}
              />
            </Col>
          </Row>
        </Col>
        {selectedUserId ? (
          <Col span={16}>
            <Row style={{ marginBottom: 25, marginTop: 5 }}>
              <Col span={18}>
                {mesuDados.profile_id < 4 ? (
                  <Button
                    type="primary"
                    icon={<FiPlus />}
                    onClick={showModalNewStructure}
                  >
                    Novo
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    icon={<FiPlus />}
                    disabled={true}
                    onClick={showModalNewStructure}
                  >
                    Novo
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  rowKey="id"
                  size="small"
                  pagination={{ pageSize: 1000 }}
                  scroll={{
                    y: 445,
                  }}
                  columns={columns2}
                  dataSource={searchCompanyData2}
                  loading={isLoading2}
                />
              </Col>
            </Row>
          </Col>
        ) : (
          <></>
        )}
        <Modal
          visible={modalNewStructure}
          onCancel={handleCancel}
          onOk={handleOk}
          width={1000}
          okText={"Salvar"}
        >
          <Row>
            <Col span={24} style={{ textAlign: "center" }}>
              <Typography.Title level={4}>
                {selectedTaskId
                  ? "Atualizar a Estrutura Comercial desse Usuário"
                  : "Adicione uma Estrutura Comercial"}
              </Typography.Title>
            </Col>

            <Select
              style={{ width: "100%", margin: "10px 0" }}
              size="large"
              dropdownMatchSelectWidth={false}
              placeholder=""
              optionFilterProp="children"
              onChange={(value) => [setEnable2(true), setLevel1(value)]}
              value={level1}
            >
              {list.map((initiative: any, items: any) =>
                initiative.level0_external_name != null &&
                mesuDados.profile_id === 1 ? (
                  <Option value={initiative.id}>
                    {mesuDados.profile_id === 1 &&
                    initiative.level0_external_name != null
                      ? initiative.level0_external_name
                      : undefined}
                    {mesuDados.profile_id === 1 &&
                    initiative.level1_external_name != null
                      ? " - " + initiative.level1_external_name
                      : undefined}
                    {mesuDados.profile_id === 1 &&
                    initiative.level2_external_name != null
                      ? " - " + initiative.level2_external_name
                      : undefined}
                    {mesuDados.profile_id === 1 &&
                    initiative.level3_external_name != null
                      ? " - " + initiative.level3_external_name
                      : undefined}
                    {mesuDados.profile_id === 1 &&
                    initiative.level4_external_name != null
                      ? " - " + initiative.level4_external_name
                      : undefined}
                    {mesuDados.profile_id === 2 &&
                    initiative.level1_external_name != null &&
                    initiative.level2_external_name != null
                      ? initiative.level1_external_name
                      : undefined}
                    {mesuDados.profile_id === 2 &&
                    initiative.level2_external_name != null
                      ? " - " + initiative.level2_external_name
                      : undefined}
                    {mesuDados.profile_id === 2 &&
                    initiative.level3_external_name != null
                      ? " - " + initiative.level3_external_name
                      : undefined}
                    {mesuDados.profile_id === 2 &&
                    initiative.level4_external_name != null
                      ? " - " + initiative.level4_external_name
                      : undefined}
                    {mesuDados.profile_id === 3 &&
                    initiative.level2_external_name != null &&
                    initiative.level3_external_name != null
                      ? initiative.level2_external_name
                      : undefined}
                    {mesuDados.profile_id === 3 &&
                    initiative.level3_external_name != null
                      ? " - " + initiative.level3_external_name
                      : undefined}
                    {mesuDados.profile_id === 3 &&
                    initiative.level4_external_name != null
                      ? " - " + initiative.level4_external_name
                      : undefined}
                  </Option>
                ) : initiative.level2_external_name != null &&
                  mesuDados.profile_id === 2 ? (
                  <Option value={initiative.id}>
                    {mesuDados.profile_id === 2 &&
                    initiative.level1_external_name != null &&
                    initiative.level2_external_name != null
                      ? initiative.level1_external_name
                      : undefined}
                    {mesuDados.profile_id === 2 &&
                    initiative.level2_external_name != null
                      ? " - " + initiative.level2_external_name
                      : undefined}
                    {mesuDados.profile_id === 2 &&
                    initiative.level3_external_name != null
                      ? " - " + initiative.level3_external_name
                      : undefined}
                    {mesuDados.profile_id === 2 &&
                    initiative.level4_external_name != null
                      ? " - " + initiative.level4_external_name
                      : undefined}
                    {mesuDados.profile_id === 3 &&
                    initiative.level2_external_name != null &&
                    initiative.level3_external_name != null
                      ? initiative.level2_external_name
                      : undefined}
                    {mesuDados.profile_id === 3 &&
                    initiative.level3_external_name != null
                      ? " - " + initiative.level3_external_name
                      : undefined}
                    {mesuDados.profile_id === 3 &&
                    initiative.level4_external_name != null
                      ? " - " + initiative.level4_external_name
                      : undefined}
                  </Option>
                ) : initiative.level3_external_name != null &&
                  mesuDados.profile_id === 3 ? (
                  <Option value={initiative.id}>
                    {mesuDados.profile_id === 2 &&
                    initiative.level1_external_name != null &&
                    initiative.level2_external_name != null
                      ? initiative.level1_external_name
                      : undefined}
                    {mesuDados.profile_id === 3 &&
                    initiative.level2_external_name != null &&
                    initiative.level3_external_name != null
                      ? initiative.level2_external_name
                      : undefined}
                    {mesuDados.profile_id === 3 &&
                    initiative.level3_external_name != null
                      ? " - " + initiative.level3_external_name
                      : undefined}
                    {mesuDados.profile_id === 3 &&
                    initiative.level4_external_name != null
                      ? " - " + initiative.level4_external_name
                      : undefined}
                  </Option>
                ) : undefined
              )}
            </Select>
          </Row>
        </Modal>
      </Row>
    </Breadcrumb>
  );
}
