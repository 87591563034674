import {
  Button,
  Card,
  Col,
  Row,
  Table,
  Modal,
  Typography,
  Input,
  Popconfirm,
  message,
  Select,
} from "antd";
import { useState, useCallback, useEffect } from "react";
import { FiEdit2, FiFilter, FiPlus, FiSearch, FiTrash } from "react-icons/fi";
import { Breadcrumb } from "../../../components/Breadcrumb";
import CompanyCropService from "../../../services/Company/Crop";
import { useAppSelector } from "../../../hooks";
import { Actions } from "./styles";
import { LoadingOutlined } from "@ant-design/icons";
interface TasksProps {
  id: number;
  crop_id: number;
  name: string;
  external_name: string;
  type_name: string;
  updatedAt: number;
  createdAt: string;
}
interface InitiativeProps {
  type_name: string;
  id: number;
  company_id: number;
  name: string;
  thematic_name: string;
}
const { Option } = Select;
export function Crop() {
  const [modalNewCrop, setModalNewCrop] = useState(false);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const [textError, setTextError] = useState("");
  const [modalError, setModalError] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const [selectedInitiativeId, setSelectedInitiativeId] = useState<
    number | null
  >(null);
  const [external_name, setExternal_name] = useState<string>("");
  const [listInitiatives, setListInitiatives] = useState<InitiativeProps[]>([]);
  const [name, setName] = useState<string>("");
  const [type_name, setType_name] = useState<any>(null);
  const [updatedAt, setUpdatedAt] = useState<any>(null);
  const [listTasks, setListTasks] = useState<TasksProps[]>([]);
  const [listTasks2, setListTasks2] = useState<TasksProps[]>([]);
  const [searchData, setSearchData] = useState<any | TasksProps[]>([]);
  const [search, setSearch] = useState("");
  const getListTasks = useCallback(async () => {
    try {
      setModalNewCrop(false);
      setLoading(true);
      const response: any[] = await CompanyCropService.getCompanyCrop(
        companyId
      );
      const formattedTasks = response.map((rawTask) => {
        return {
          id: rawTask.id,
          crop_id: rawTask.crop_id,
          name: rawTask.crop.name,
          external_name: rawTask.external_name,
          type_name: rawTask.crop.type_name,
          updatedAt: rawTask.updatedAt ?? "",
          createdAt: rawTask.createdAt,
        };
      });
      setListTasks(
        formattedTasks.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        }) as []
      );
      setSearchData(
        formattedTasks.sort(function (a, b) {
          if (a.id < b.id) {
            return -1;
          } else {
            return 1;
          }
        }) as []
      );
    } catch (e) {
      console.error("Falha ao buscar tarefas: ");
      setModalError(true);
      setTextError(e.message);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);
  //getCompanyGlobalCrop
  const getListInitiatives = useCallback(async () => {
    const response: InitiativeProps[] =
      await CompanyCropService.getCompanyGlobalCrop();
    setListInitiatives(response);
  }, [companyId]);
  const getListGlCrop = useCallback(async () => {
    setLoading(true);
    try {
      const response: any[] = await CompanyCropService.getCompanyGlobalCrop();
      const listTasks2 = response.map((rawTask) => {
        return {
          id: rawTask.id,
          name: rawTask.name,
          type_name: rawTask.type_name,
          updatedAt: rawTask.updatedAt ?? "",
          createdAt: rawTask.createdAt,
        };
      });
      setListTasks2(listTasks2 as []);
    } catch (e) {
      console.error("Falha ao buscar");
      setModalError(true);
      setTextError(e.message);
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, []);
  function handleClickEdit(task: TasksProps) {
    console.log(task);
    setSelectedTaskId(task.id ?? null);
    setName(task.name);
    setExternal_name(task.external_name);
    setType_name(task.type_name);
    setSelectedInitiativeId(task.crop_id);
    setUpdatedAt(task.updatedAt);
    setModalNewCrop(true);
  }
  async function confirmDelete(task: TasksProps) {
    try {
      console.log(task);
      await CompanyCropService.deleteTask(companyId, task.id);
    } catch (e) {
      console.error("Falha ao excluir: ");
      message.error("Falha ao excluir.");
      setModalError(true);
      setTextError(e.message);
      console.error(e);
    }
    await getListTasks();
  }
  useEffect(() => {
    getListTasks();
    getListGlCrop();
    getListInitiatives();
  }, []);
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nome externo",
      dataIndex: "external_name",
      key: "external_name",
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tipo de Cultivo",
      dataIndex: "type_name",
      key: "type_name",
    },
    {
      title: "Ações",
      dataIndex: "uf",
      key: "uf",
      width: 150,
      align: "center" as const,
      render: (_: null, record: TasksProps) => {
        return (
          <Actions>
            <FiEdit2
              size={18}
              color="#00CC66"
              onClick={() => handleClickEdit(record)}
            />
            <Popconfirm
              title="Tem certeza que deseja excluir essa tarefa?"
              onConfirm={() => confirmDelete(record)}
              okText="Excluir"
              okButtonProps={{ danger: true }}
              cancelText="Cancelar"
            >
              <FiTrash size={18} color="#00CC66" />
            </Popconfirm>
          </Actions>
        );
      },
    },
  ];
  useEffect(() => {
    filterTable();
    function filterTable() {
      if (!search) {
        setSearchData(listTasks);
      } else {
        const array = listTasks.filter(
          (record) =>
            !search ||
            record["external_name"]
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            record["type_name"].toLowerCase().includes(search.toLowerCase()) ||
            record["name"].toLowerCase().indexOf(search.toLowerCase()) > -1
        );
        setSearchData(array);
      }
    }
  }, [search, listTasks]);
  function showModalNewCrop() {
    setModalNewCrop(true);
  }

  function handleCancel() {
    setModalNewCrop(false);
    setSelectedTaskId(null);
    setName("");
    setType_name(null);
    setUpdatedAt(null);
    setSelectedInitiativeId(null);
    setExternal_name("");
    setModalError(false);
  }
  async function handleSave() {
    setModalNewCrop(false);
    var data = new Date();
    var dia =
      data.getFullYear() +
      "-" +
      (data.getMonth() + 1) +
      "-" +
      data.getDate() +
      " " +
      data.getHours() +
      ":" +
      data.getMinutes() +
      ":" +
      data.getSeconds();
    if (selectedTaskId) {
      console.log("update");
      try {
        await CompanyCropService.updateTask(
          companyId,
          {
            id: selectedTaskId,
            crop_id: selectedInitiativeId,
            external_name:
              external_name[0].toUpperCase() +
              external_name.slice(1).toLowerCase(),
            type_name: type_name,
            updatedAt: dia,
          },
          selectedTaskId
        );
      } catch (e) {
        console.log("Falha ao atualizar");
        setModalError(true);
        setTextError(e.message);
        console.error(e);
      }
    } else {
      console.log("create" + selectedInitiativeId);
      try {
        await CompanyCropService.createTask(companyId, {
          crop_id: selectedInitiativeId,
          external_name:
            external_name[0].toUpperCase() +
            external_name.slice(1).toLowerCase(),
          creatdAt: dia,
          updatedAt: dia,
        });
      } catch (e) {
        console.log("Falha ao criar");
        setModalError(true);
        setTextError(e.message);
        console.error(e);
      }
    }

    await getListTasks();
    handleCancel();
  }
  function disableSave(): boolean {
    return !selectedInitiativeId || external_name.trim().length === 0;
  }
  return (
    <Breadcrumb path="Cultivo" child={["Cultivo"]}>
      <Row gutter={8}>
        <Col span={24}>
          <Card bordered={false}>
            <Row justify="space-between" gutter={[16, 16]}>
              <Col span={18}>
                <Button
                  type="primary"
                  icon={<FiPlus />}
                  onClick={showModalNewCrop}
                >
                  Novo
                </Button>
              </Col>
              <Col span={6}>
                <Input
                  allowClear
                  placeholder="Pesquisar"
                  prefix={<FiSearch color="#00CC66" />}
                  suffix={<FiFilter color="#00CC66" />}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Col>
              <Col span={24}>
                <Table
                  rowKey="id"
                  size="small"
                  columns={columns}
                  dataSource={searchData}
                  loading={isLoading}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Modal
          visible={modalNewCrop}
          okButtonProps={{ disabled: disableSave() }}
          okText={"Salvar"}
          onOk={handleSave}
          width={800}
          onCancel={handleCancel}
          title={selectedTaskId ? "Atualizar Cultivo" : "Novo Cultivo"}
        >
          <Row justify="center" gutter={20}>
            <Col span={12} style={{ textAlign: "center" }}>
              <>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text
                    style={{
                      whiteSpace: "nowrap",
                      marginRight: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Tipo de Cultivo
                  </Typography.Text>
                  <Select
                    style={{ width: "100%", margin: "10px 0" }}
                    size="large"
                    dropdownMatchSelectWidth={false}
                    showSearch
                    placeholder="Tipo de Cultivo"
                    optionFilterProp="children"
                    onChange={(value) => setSelectedInitiativeId(value)}
                    value={selectedInitiativeId}
                  >
                    {listInitiatives.map((initiative) => (
                      <Option value={initiative.id}>
                        {initiative.name}
                        {" - "}
                        {initiative.type_name}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </>
              <>
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text
                    style={{
                      whiteSpace: "nowrap",
                      marginRight: 10,
                      fontWeight: "bold",
                    }}
                  >
                    Nome Externo
                  </Typography.Text>
                  <Input
                    style={{ width: "100%", margin: "10px 0" }}
                    size="large"
                    placeholder="Nome externo"
                    value={
                      external_name != ""
                        ? external_name[0].toUpperCase() +
                          external_name.slice(1).toLowerCase()
                        : external_name
                    }
                    onChange={(e) => setExternal_name(e.target.value)}
                  />
                </Col>
              </>
            </Col>
          </Row>
        </Modal>
      </Row>
    </Breadcrumb>
  );
}
