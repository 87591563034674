import {
  Card,
  Col,
  Row,
  Table,
  Tag,
  Select,
  DatePicker,
  Statistic,
  Button,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../../../hooks";
import ActionPlanService from "../../../../services/SalesPlanning/ActionPlan";
import PowerBi_all from "../../../../services/Global/PowerBi";
import "./index.css";
import dayjs from "dayjs";
import { Bar, Pie } from "@ant-design/charts";
const { Option } = Select;
const { RangePicker } = DatePicker;
export function ActionPlanDashboard() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const Icon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [listTasks, setListTasks] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [timeAutoUpdate, setTimeAutoUpdate] = useState<number>(0);
  const [counter, setCounter] = useState<number | null>(null);
  const [OptionsRegional, setOptionsRegional] = useState<
    { id: number; name: string } | any
  >([]);
  const [searchData, setSearchData] = useState<any[]>([]);
  const [dataIniciativeConfig, setDataIniciativeConfig] = useState<any[]>([]);
  const [dataIniciativeStatusConfig, setDataIniciativeStatusConfig] = useState<
    any[]
  >([]);
  const [isLoading, setLoading] = useState(false);
  const [filteredStatus, setFilteredStatus] = useState<string | null>(null);
  const [filterRegional, setFilterRegional] = useState<number | null>(0);
  const [filterFiliall, setFilterFiliall] = useState<number | 0>(0);
  const [filialOptions, setFilialOptions] = useState([]);
  const [startDate1, setStartDate1] = useState<any>(null);
  const [startDate2, setStartDate2] = useState<any>(null);
  const [endDate1, setEndDate1] = useState<any>(null);
  const [endDate2, setEndDate2] = useState<any>(null);
  const [statusTotals, setStatusTotals] = useState({
    totalInitiatives: 0,
    concluida: 0,
    naoIniciada: 0,
    emAndamento: 0,
    vencida: 0,
    atrasada: 0,
    cancelada: 0,
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Tarefa",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Atividade",
      dataIndex: "Atividade",
      key: "Atividade",
    },
    {
      title: "Filial",
      dataIndex: "filial",
      key: "filial",
    },
    {
      title: "Data de início",
      dataIndex: "data_inicio",
      key: "data_inicio",
    },
    {
      title: "Data de entrega",
      dataIndex: "data_fim",
      key: "data_fim",
    },
    {
      title: "Valor Ação",
      dataIndex: "action_value",
      key: "action_value",
      render: (text: string | null) => {
        return text
          ? Number(text).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          : "";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => {
        return <Tag color={getTagColor(text)}>{getTagText(text)}</Tag>;
      },
    },
  ];

  const LimparFiltros = () => {
    setFilterRegional(0);
    setFilterFiliall(0);
    setFilteredStatus(null);
    setStartDate1(null);
    setStartDate2(null);
    setEndDate1(null);
    setEndDate2(null);
  };

  useEffect(() => {
    GetDataBottomUpFindRegional();
    FindData();
    console.log("componentDidMount");
  }, []);

  function getTagText(text: string) {
    switch (text) {
      case "PENDENTE":
        return "Pendente";
      case "EM ANDAMENTO":
        return "Em Andamento";
      case "CANCELADO":
        return "Cancelado";
      default:
        return "Finalizado";
    }
  }

  function getTagColor(text: string) {
    switch (text) {
      case "PENDENTE":
        return "gold";
      case "EM ANDAMENTO":
        return "purple";
      case "FINALIZADO":
        return "blue";
      default:
        return "red";
    }
  }

  async function FindData() {
    try {
      setLoading(true);
      const response = await ActionPlanService.findAllTasks(companyId);
      const formattedTasks = response.map((rawTask: any) => {
        return {
          ...rawTask,
          key: rawTask.id,
          filial_id: rawTask.level3_id,
          filial: rawTask.level3_external_name,
          description: rawTask.description,
          initiative_id: rawTask.initiative_id,
          data_inicio: moment(rawTask.initial_date).format("DD/MM/YYYY"),
          data_fim: moment(rawTask.final_date).format("DD/MM/YYYY"),
          Atividade: rawTask.initiative_name,
          action_value: rawTask.action_value,
        };
      });

      setSearchData(formattedTasks.sort((a: any, b: any) => b.key - a.key));
      setListTasks(formattedTasks);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  async function GetDataBottomUpFindRegional() {
    const response = await PowerBi_all.getButtomUpFindRegional(companyId);
    setOptionsRegional(response);
  }
  useEffect(() => {
    // Se o tempo de atualização for 0, parar a contagem e a atualização
    if (timeAutoUpdate === 0) {
      setCounter(0);
      return;
    }

    const tempoInicial = Number(timeAutoUpdate);
    setCounter(tempoInicial);

    let countdownInterval: NodeJS.Timeout;
    let dataUpdateInterval: NodeJS.Timeout;

    // Função para iniciar a contagem regressiva
    const startCountdown = () => {
      countdownInterval = setInterval(() => {
        setCounter((prev: any) => {
          if (prev <= 1) {
            return tempoInicial; // Reinicia o contador
          }
          return prev - 1;
        });
      }, 1000);
    };

    // Função para chamar FindData periodicamente
    const startDataUpdate = () => {
      dataUpdateInterval = setInterval(() => {
        console.log("timeAutoUpdate", timeAutoUpdate);
        FindData();
      }, timeAutoUpdate * 1000);
    };

    // Inicia ambos os intervalos
    startCountdown();
    startDataUpdate();

    // Limpeza dos intervalos
    return () => {
      clearInterval(countdownInterval);
      clearInterval(dataUpdateInterval);
    };
  }, [timeAutoUpdate]);

  const handleFilialChange = (value: any) => {
    setFilterFiliall(value);
  };

  function handleChangeStatus(e: any) {
    if (e === filteredStatus) {
      setFilteredStatus(null);
    } else {
      setFilteredStatus(e);
    }
  }

  useEffect(() => {
    updateFilteredDataAndCharts();
    console.log("filteredStatus, filterRegional, filterFiliall, listTasks");
  }, [
    filteredStatus,
    filterRegional,
    filterFiliall,
    listTasks,
    startDate1,
    startDate2,
    endDate1,
    endDate2,
  ]);

  const updateFilteredDataAndCharts = () => {
    const filteredTasks = listTasks.filter((record) => {
      const matchesSearch =
        !search ||
        record.description?.toLowerCase().includes(search.toLowerCase());

      const matchesStatus = !filteredStatus || record.status === filteredStatus;

      const matchesFilial =
        !filterFiliall || record.level3_id === filterFiliall;

      const matchesDateInitial =
        (!startDate1 ||
          moment(record.data_inicio, "DD/MM/YYYY").isSameOrAfter(
            moment(startDate1, "DD/MM/YYYY")
          )) &&
        (!startDate2 ||
          moment(record.data_inicio, "DD/MM/YYYY").isSameOrBefore(
            moment(startDate2, "DD/MM/YYYY")
          ));

      const matchesDateFinal =
        (!endDate1 ||
          moment(record.data_fim, "DD/MM/YYYY").isSameOrAfter(
            moment(endDate1, "DD/MM/YYYY")
          )) &&
        (!endDate2 ||
          moment(record.data_fim, "DD/MM/YYYY").isSameOrBefore(
            moment(endDate2, "DD/MM/YYYY")
          ));

      return (
        matchesSearch &&
        matchesStatus &&
        matchesFilial &&
        matchesDateInitial &&
        matchesDateFinal
      );
    });

    setSearchData(filteredTasks);

    // Atualizar gráficos e totais
    const initiativeCount = filteredTasks.reduce((acc, task) => {
      const initiativeName = task.Atividade;
      if (initiativeName) {
        acc[initiativeName] = (acc[initiativeName] || 0) + 1;
      }
      return acc;
    }, {});

    const initiativeChartData = Object.entries(initiativeCount)
      .map(([name, count]) => ({
        name: name,
        value: count,
      }))
      .sort((a: any, b: any) => b.value - a.value);
    setDataIniciativeConfig(initiativeChartData);

    const totals = calculateStatusTotals(filteredTasks);
    setStatusTotals(totals);

    const statusValueSum = filteredTasks.reduce((acc, task) => {
      const status = task.status;
      const actionValue = parseFloat(task.action_value) || 0;
      if (status) {
        acc[status] = (acc[status] || 0) + actionValue;
      }
      return acc;
    }, {});

    const statusChartData = Object.entries(statusValueSum).map(
      ([status, total]) => ({
        label: status,
        value: total,
      })
    );
    setDataIniciativeStatusConfig(statusChartData);

    const updatedFilialOptions: any = filteredTasks.reduce(
      (acc: any[], item: any) => {
        if (!acc.some((option) => option.value === item.level3_id)) {
          acc.push({ value: item.level3_id, label: item.filial });
        }
        return acc;
      },
      []
    );
    !filterFiliall && setFilialOptions(updatedFilialOptions);
  };

  const configIniciative = {
    data: dataIniciativeConfig,
    xField: (d: any) =>
      d.name.length > 20 ? `${d.name.slice(0, 20)}...` : d.name,
    yField: "value",
    colorField: "name",
    height: 300,
    label: {
      text: "value",
      style: {
        textAlign: (d: any) => (+d.total_volume > 100 ? "right" : "start"),
        fill: (d: any) => (+d.total_volume > 100 ? "#fff" : "#000"),
        dx: (d: any) => (+d.total_volume > 100 ? -5 : 5),
      },
    },
    legend: false,
  };

  const configIniciativeStatus = {
    data: dataIniciativeStatusConfig,
    angleField: "value",
    colorField: "label",
    radius: 0.8,
    height: 300,
    label: {
      text: (d: any) =>
        `${d.label}\n R$ ${d.value.toFixed(2).replace(".", ",")}`,
      position: "spider",
    },
  };

  const calculateStatusTotals = (tasks: any[]) => {
    const totals = {
      totalInitiatives: tasks.length,
      concluida: tasks.filter((task) => task.status === "FINALIZADO").length,
      naoIniciada: tasks.filter((task) => task.status === "PENDENTE").length,
      emAndamento: tasks.filter((task) => task.status === "EM ANDAMENTO")
        .length,
      vencida: tasks.filter(
        (task) =>
          task.status === "PENDENTE" &&
          moment(task.data_fim, "DD/MM/YYYY").isBefore(moment())
      ).length,
      atrasada: tasks.filter(
        (task) =>
          task.status === "EM ANDAMENTO" &&
          moment(task.data_fim, "DD/MM/YYYY").isBefore(moment())
      ).length,
      cancelada: tasks.filter((task) => task.status === "CANCELADO").length,
    };
    return totals;
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <main className="main-container">
          <div className="sales-projection-header">
            <Button type="primary" size="large" onClick={() => LimparFiltros()}>
              Limpar Filtros
            </Button>
            <div className="header-title">
              <span>Plano de Ação PGC</span>
            </div>
            <div>
              <b style={{ color: "#fff" }}>Atualizar: </b>
              <Select
                defaultValue={0}
                value={timeAutoUpdate}
                onChange={(value) => setTimeAutoUpdate(value)}
                style={{ width: 120 }}
              >
                <Option value={0}>Não</Option>
                <Option value={10}>10 Seg</Option>
                <Option value={15}>15 Seg</Option>
                <Option value={20}>20 Seg</Option>
              </Select>
            </div>
          </div>
        </main>
        {timeAutoUpdate !== 0 && (
          <div className="divtimer">
            <div className="timer">Atualiza em {counter} segundos</div>
          </div>
        )}
        <Card bordered={false}>
          <Row>
            <Col span={24}>
              <div className="report-grid">
                {[
                  {
                    title: "Total Iniciativas",
                    color: "#000",
                    value: statusTotals.totalInitiatives,
                  },
                  {
                    title: "Concluída",
                    color: "#00cc66",
                    value: statusTotals.concluida,
                  },
                  {
                    title: "Não Iniciada",
                    color: "#fff",
                    value: statusTotals.naoIniciada,
                  },
                  {
                    title: "Em Andamento",
                    color: "#0075ba",
                    value: statusTotals.emAndamento,
                  },
                  {
                    title: "Vencida",
                    color: "#fb2f2e",
                    value: statusTotals.vencida,
                  },
                  {
                    title: "Atrasada",
                    color: "#fb2f2e",
                    value: statusTotals.atrasada,
                  },
                  {
                    title: "Cancelada",
                    color: "#8d66c1",
                    value: statusTotals.cancelada,
                  },
                ].map((item, index) => (
                  <Col key={index} className="report-item">
                    <Card
                      title={item.title}
                      bordered={true}
                      className="report-card"
                    >
                      <div className="report-card-content">
                        <div
                          className="report-card-circle"
                          style={{ backgroundColor: item.color }}
                        />
                        <Statistic
                          value={item.value}
                          valueStyle={{ color: "#3f8600" }}
                        />
                      </div>
                    </Card>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={3}>
              <Card title="Filial" bordered={false} className="card-filter">
                <div className="report-card-content">
                  <Select
                    placeholder="Filial"
                    defaultValue={{
                      label: "Todos",
                      value: 0,
                    }}
                    value={filterFiliall}
                    onChange={handleFilialChange}
                    style={{ width: "100%" }}
                    options={[
                      { value: 0, label: "Todos" },
                      ...filialOptions.map((item: any) => ({
                        label: item.label,
                        value: item.value,
                      })),
                    ]}
                  />
                </div>
              </Card>
              <Card title="Status" bordered={false} className="card-filter">
                <div className="report-card-content">
                  <Select
                    placeholder="Selecione o status"
                    onChange={(e) => handleChangeStatus(e)}
                    value={filteredStatus} // O estado atual dos filtros
                    style={{ width: "100%" }}
                    options={[
                      { label: "Todos", value: null },
                      { label: "PENDENTE", value: "PENDENTE" },
                      { label: "EM ANDAMENTO", value: "EM ANDAMENTO" },
                      { label: "CANCELADO", value: "CANCELADO" },
                      { label: "FINALIZADO", value: "FINALIZADO" },
                    ]}
                  />
                </div>
              </Card>
              <Card
                title="Data Inicial"
                bordered={false}
                className="card-filter"
              >
                <div className="report-card-content">
                  <RangePicker
                    style={{ width: "100%" }}
                    format="DD/MM/YYYY"
                    value={[
                      startDate1 ? dayjs(startDate1, "DD/MM/YYYY") : null,
                      startDate2 ? dayjs(startDate2, "DD/MM/YYYY") : null,
                    ]}
                    onChange={(value, dateString) => {
                      setStartDate1(dateString[0]);
                      setStartDate2(dateString[1]);
                    }}
                  />
                </div>
              </Card>
              <Card title="Data Final" bordered={false} className="card-filter">
                <div className="report-card-content">
                  <RangePicker
                    style={{ width: "100%" }}
                    format="DD/MM/YYYY"
                    value={[
                      endDate1 ? dayjs(endDate1, "DD/MM/YYYY") : null,
                      endDate2 ? dayjs(endDate2, "DD/MM/YYYY") : null,
                    ]}
                    onChange={(value, dateString) => {
                      setEndDate1(dateString[0]);
                      setEndDate2(dateString[1]);
                    }}
                  />
                </div>
              </Card>
            </Col>
            <Col span={21} style={{ marginTop: 5 }}>
              <div className="report-grid-simple">
                <Card
                  title={
                    <>
                      <strong>Iniciativa</strong>{" "}
                    </>
                  }
                  bordered={true}
                  className="chart-card-2"
                >
                  <div className="iframe-container">
                    <Bar {...configIniciative} />
                  </div>
                </Card>

                <Card
                  title={
                    <>
                      <strong>Valor Iniciativas Por Status</strong>{" "}
                    </>
                  }
                  bordered={true}
                  className="chart-card-2"
                >
                  <div className="iframe-container">
                    <Pie {...configIniciativeStatus} />
                  </div>
                </Card>
              </div>
              <Table
                size="small"
                columns={columns}
                dataSource={searchData}
                pagination={false}
                loading={{ indicator: Icon, spinning: isLoading }}
                scroll={{ y: 210 }}
                summary={(pageData) => {
                  const total = pageData.reduce((sum, record) => {
                    const value = parseFloat(record.action_value) || 0;
                    return sum + value;
                  }, 0);
                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell
                          colSpan={6}
                          align="right"
                          index={-1}
                        >
                          <strong>Total:</strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={0}>
                          <strong>
                            {total.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </strong>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1} />
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
