import { PolicePrivacy } from "../../PolicePrivacy";
import { PowerBi_Dashboard } from "../../PowerBi";
import { Owner_empresas } from "../../Owner";
import { SelectCompany } from "../SelectCompany";
import { SelectModule } from "../SelectModule";

export const ParametersRoutes = [
  { path: "/select-module", element: <SelectModule /> },
  { path: "/", hash: "Selecionar empresa", element: <SelectCompany /> },
  { path: "/Dashboard", element: <PowerBi_Dashboard /> },
  { path: "/Owner_empresas", element: <Owner_empresas /> },
  { path: "/privacidade", element: <PolicePrivacy />},
  { path: "*", element: <h1>Not Found</h1>
}
];
