/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-eval */
import { Button, Card, Col, Modal, Row, Select, Statistic } from "antd";
import { useAppSelector } from "../../../hooks";
import { useState, useEffect } from "react";
import PowerBi_all from "../../../services/Global/PowerBi";
import BottomUpService from "../../../services/SalesPlanning/BottomUp";
import { FilterOutlined } from "@ant-design/icons";

import { Bar } from "@ant-design/charts";
import { Column } from "@ant-design/plots";
import "./index.css";
const Option = Select.Option;
export function BottomUPDashboard() {
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const parameters = useAppSelector((state) => state.entities.parameter);
  const [year0, setYear0] = useState<string | undefined>("");
  const [year1, setYear1] = useState<string | undefined>("");
  const [year2, setYear2] = useState<string | undefined>("");
  const [year3, setYear3] = useState<string | undefined>("");
  const [year4, setYear4] = useState<string | undefined>("");
  const [year5, setYear5] = useState<string | undefined>("");
  const [qdt_year, setQdt_year] = useState<string | undefined>("0");
  const [years, setYears] = useState<any>(["year0", "year1"]);
  const [DataProjecaodevendas, setDataProjecaodevendas] = useState<any>([]);
  const [OptionsRegional, setOptionsRegional] = useState<
    { id: number; name: string } | any
  >([]);
  const [somaAno0, setSomaAno0] = useState<any>(0);
  const [somaAno1, setSomaAno1] = useState<any>(0);
  const [somaAno2, setSomaAno2] = useState<any>(0);
  const [somaAno3, setSomaAno3] = useState<any>(0);
  const [somaAno4, setSomaAno4] = useState<any>(0);
  const [somaAno5, setSomaAno5] = useState<any>(0);
  const [filterRegional, setFilterRegional] = useState<number>(0);
  const [filterFilialModal, setFilterFilialModal] = useState<number>(0);
  const [valoresProdutos, setValoresProdutos] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [timeAtualizarTelaAuto, setTimeAtualizarTelaAuto] =
    useState<string>("0");
  const [contador, setContador] = useState<any>();

  const open = () => {
    setIsModalVisible(true);
    GetDataButtomUpFilial(filterRegional);
  };

  const handleCancel = () => {
    setFilterFilialModal(0);
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    handleFilterModal();
  };

  const handleFilter = () => {
    GetDataBottomUp();
    GetDataButtomUpFilial(filterRegional);
    setValoresProdutos([]);
  };
  const handleFilterModal = () => {
    GetDataButtomUpFilial(filterRegional, filterFilialModal);
  };
  const configurarAtualizacaoAutomatica = () => {
    if (timeAtualizarTelaAuto !== "0") {
      const interval = setInterval(() => {
        handleFilter();
      }, Number.parseInt(timeAtualizarTelaAuto) * 1000);
      return () => clearInterval(interval);
    }
  };

  const LimparFiltros = () => {
    setFilterRegional(0);
    setFilterFilialModal(0);
    setValoresProdutos([]);
  };

  useEffect(() => {
    GetDataBottomUpFindRegional();
  }, []);

  useEffect(() => {
    setYears(yearsOptions(Number(qdt_year)));
  }, [qdt_year]);

  useEffect(configurarAtualizacaoAutomatica, [timeAtualizarTelaAuto]);

  useEffect(() => {
    if (timeAtualizarTelaAuto === "0") {
      setContador(null); // Reseta o contador quando "Não" é selecionado
      return;
    }

    // Converte o tempo selecionado para número
    const tempoInicial = parseInt(timeAtualizarTelaAuto, 10);

    setContador(tempoInicial);

    // Inicia a contagem decrescente
    const intervalId = setInterval(() => {
      setContador((prev: any) => {
        if (prev > 1) {
          return prev - 1;
        } else {
          return tempoInicial; // Reinicia o contador
        }
      });
    }, 1000);

    // Limpa o intervalo se o componente for desmontado ou o tempo mudar
    return () => clearInterval(intervalId);
  }, [timeAtualizarTelaAuto]);
  const handleRegionChange = (value: any) => {
    setFilterRegional(value);
  };

  const handleFilialChangeModal = (value: any) => {
    GetDataBottomUps(value);
    setFilterFilialModal(value);
  };

  async function GetDataBottomUps(id: number) {
    if (id === 0) {
      setValoresProdutos([]);
      return;
    }
    const response = await BottomUpService.getTableProduct(companyId, id);

    // Transformar os dados no formato desejado
    const transformedData = response.records
      .map((item: any) => {
        // Mapeamento dos anos e valores correspondentes
        const years = [
          // { year_label: "year0", total_volume: item.total_base_line },
          { year_label: "year1", total_volume: item.total_year1 },
          // { year_label: "year2", total_volume: item.total_year2 },
          // { year_label: "year3", total_volume: item.total_year3 },
          // { year_label: "year4", total_volume: item.total_year4 },
          // { year_label: "year5", total_volume: item.total_year5 },
        ];

        // Criar o array de objetos com os dados transformados
        return years.map((yearData) => ({
          name: item.product_name, // Usando product_name como name
          year_label: yearData.year_label, // Adicionando year_label
          total_volume: yearData.total_volume, // Adicionando o volume do ano correspondente
        }));
      })
      .flat(); // Usando .flat() para achatar o array de arrays em um único array

    // Agrupar os dados pelo 'name' e somar os volumes
    const groupedData = transformedData.reduce((acc: any, curr: any) => {
      const key = curr.name; // Chave baseada no nome do produto
      if (!acc[key]) {
        acc[key] = { name: curr.name, total_volume: 0 };
      }
      // Somar os volumes
      acc[key].total_volume = parseFloat(
        acc[key].total_volume + curr.total_volume
      );
      return acc;
    }, {});

    // Obter os valores agrupados como um array
    const finalData = Object.values(groupedData)
      .filter(
        (item: any) => item.total_volume !== 0 // Filtrar os produtos com volume total maior que 0
      )
      .sort((a: any, b: any) => b.total_volume - a.total_volume);

    // Retornar ou configurar os valores do produto
    setValoresProdutos(finalData);
  }

  useEffect(() => {
    setYear0(
      parameters.find((value) => value.name === "YEAR_BASE_LINE_LABEL")?.value
    );
    setYear1(parameters.find((value) => value.name === "YEAR1_LABEL")?.value);
    setYear2(parameters.find((value) => value.name === "YEAR2_LABEL")?.value);
    setYear3(parameters.find((value) => value.name === "YEAR3_LABEL")?.value);
    setYear4(parameters.find((value) => value.name === "YEAR4_LABEL")?.value);
    setYear5(parameters.find((value) => value.name === "YEAR5_LABEL")?.value);
    setQdt_year(parameters.find((value) => value.name === "QTD_YEARS")?.value);
  }, [parameters]);

  const [DataFilial, setDataFilial] = useState<any>([]);
  const [filialOptions, setFilialOptions] = useState([]);

  async function GetDataButtomUpFilial(filtro: number, filterFilial?: number) {
    const response = await PowerBi_all.GetButtomUpFilial(
      companyId,
      years || yearsOptions(Number(qdt_year)),
      filtro,
      filterFilial
    );

    const updatedData = response.map((item: any) => {
      let newYearLabel = item.year_label;

      // Substitui o year_label com base nos anos
      if (item.year_label === "year0") newYearLabel = year0;
      if (item.year_label === "year1") newYearLabel = year1;
      if (item.year_label === "year2") newYearLabel = year2;
      if (item.year_label === "year3") newYearLabel = year3;
      if (item.year_label === "year4") newYearLabel = year4;
      if (item.year_label === "year5") newYearLabel = year5;
      // Abrevia o valor de total_volume para "K", "M", etc.
      const abbreviatedTotalVolume = item.total_volume;

      return {
        ...item,
        year_label: newYearLabel, // Atualiza year_label no item
        total_volume: abbreviatedTotalVolume,
      };
    });
    setDataFilial(updatedData);

    const seen = new Set();
    const uniqueFilialOptions = updatedData.filter((item: any) => {
      if (!seen.has(item.name)) {
        seen.add(item.name); // Marca o nome como visto
        return true;
      }
      return false;
    });
    setFilialOptions(uniqueFilialOptions);
    //filialOptions.length <= 0 && setFilialOptions(uniqueFilialOptions);
  }

  const yearsOptions = (qdt_year: number) => {
    let yearss = [];
    for (let i = 0; i <= qdt_year; i++) {
      yearss.push(`year${i}`);
    }
    // setYears(yearss);
    return yearss;
  };
  const handleChange = (value: any) => {
    setYears(value);
  };

  async function GetDataBottomUpFindRegional() {
    const response = await PowerBi_all.getButtomUpFindRegional(companyId);
    setOptionsRegional(response);
  }
  const formatNumber = (value: number) => {
    if (value >= 1_000_000) {
      return (value / 1_000_000).toFixed(1) + "M"; // Milhões
    } else if (value >= 1_000) {
      return (value / 1_000).toFixed(1) + "K"; // Mil
    } else {
      return value.toString(); // Valores menores que mil
    }
  };
  async function GetDataBottomUp() {
    const response = await PowerBi_all.GetButtomUpRegional(
      companyId,
      years || yearsOptions(Number(qdt_year)),
      filterRegional
    );
    // Agrupar e somar os valores de total_volume por name e year_label
    const groupedData = response.reduce((acc: any[], item: any) => {
      const existing = acc.find(
        (entry: any) =>
          entry.year_label === item.year_label && entry.name === item.name
      );
      if (existing) {
        existing.total_volume += item.total_volume; // Soma o volume para os mesmos name e year_label
      } else {
        acc.push({
          id: item.id,
          name: item.name,
          year_label: item.year_label, // Mantém o valor original de year_label
          total_volume: item.total_volume,
        });
      }
      return acc;
    }, []);

    // Atualiza os dados com o valor de total_volume abreviado
    const sumVolumeByYear = (data: any[], yearLabel: any) => {
      return data
        .filter((item) => item.year_label === yearLabel) // Filtra os itens pelo year_label
        .reduce((acc, item) => acc + item.total_volume, 0); // Soma os valores de total_volume
    };
    const updatedData = groupedData.map((item: any) => {
      let newYearLabel = item.year_label;

      // Substitui o year_label com base nos anos
      if (item.year_label === "year0") newYearLabel = year0;
      if (item.year_label === "year1") newYearLabel = year1;
      if (item.year_label === "year2") newYearLabel = year2;
      if (item.year_label === "year3") newYearLabel = year3;
      if (item.year_label === "year4") newYearLabel = year4;
      if (item.year_label === "year5") newYearLabel = year5;
      // Abrevia o valor de total_volume para "K", "M", etc.
      const abbreviatedTotalVolume = item.total_volume;

      return {
        ...item,
        year_label: newYearLabel, // Atualiza year_label no item
        total_volume: abbreviatedTotalVolume, // Atualiza o valor de total_volume com a abreviação
      };
    });

    // Setar os dados atualizados
    const somaAno0 = sumVolumeByYear(response, "year0");
    const somaAno1 = sumVolumeByYear(response, "year1");
    const somaAno2 = sumVolumeByYear(response, "year2");
    const somaAno3 = sumVolumeByYear(response, "year3");
    const somaAno4 = sumVolumeByYear(response, "year4");
    const somaAno5 = sumVolumeByYear(response, "year5");

    // Armazena os valores somados nas variáveis correspondentes
    setSomaAno0(somaAno0);
    setSomaAno1(somaAno1);
    setSomaAno2(somaAno2);
    setSomaAno3(somaAno3);
    setSomaAno4(somaAno4);
    setSomaAno5(somaAno5);

    setDataProjecaodevendas(updatedData);
  }

  const configRegional = {
    data: DataProjecaodevendas,
    xField: "name",
    yField: (d: any) => d.total_volume,
    colorField: "year_label",
    seriesField: "year_label",
    disableLegend: true,
    label: {
      text: (d: any) => formatNumber(d.total_volume),
      style: {
        width: "100%",
        formatter: "0",
        textAlign: (d: any) => (+d.value >= 0 ? "right" : "start"),
        fontSize: 12,
        fontWeight: (d: any) => (+d.value > 1 ? 600 : 400),
        wordBreak: "break-all",
        opacity: (d: any) => (+d.value > 1 ? 1 : 0.5),
        wrapWidth: 150,
        wrapDirection: (d: any) => (+d.value >= 0 ? "end" : "start"),
        overflow: "truncate",
        ellipsis: (d: any) => (+d.value > 1 ? true : false),
        dx: (d: any) => (+d.value >= 0 ? -5 : 5),
        fill: (d: any) => (+d.value >= 0 ? "#fff" : "#000"),
        stroke: (d: any) => (+d.value >= 0 ? "#fff" : "#000"),
      },
    },
    legend: {
      color: { labelFontSize: 18, itemLabelFontSize: 18 },
    },
    group: true,
    height: 500,
    style: {
      inset: 5,
      radiusTopLeft: 10,
      radiusTopRight: 10,
    },
  };

  const dynamicColumnWidthRatio = DataFilial.length <= 3 ? 0.1 : 0.5;
  const configFilal = {
    data: DataFilial,
    theme: "academy",
    xField: "name",
    yField: "total_volume",
    colorField: "year_label",
    seriesField: "year_label",
    disableLegend: true,
    height: 500,
    group: true,
    labelFontSize: 18,
    barWidthRatio: 0.1, // Narrow the grouped bars
    marginRatio: 0.2,
    columnWidthRatio: dynamicColumnWidthRatio,

    legend: {
      color: { labelFontSize: 18, itemLabelFontSize: 18 },
    },
    label: {
      text: (d: any) => d.total_volume.toLocaleString("pt-BR"),
      style: {
        width: "100%",
        formatter: "0",
        textAlign: (d: any) => (+d.value > 1 ? "right" : "start"),
        fontSize: 13,
        wordBreak: "break-all",
        wrapWidth: 100,
      },
    },
    axis: {
      y: false,
    },
  };

  const yearsData = [
    { year: year0, value: somaAno0 },
    { year: year0, value: somaAno1 },
    { year: year1, value: somaAno2 },
    { year: year2, value: somaAno3 },
    { year: year3, value: somaAno4 },
    { year: year4, value: somaAno5 },
  ];

  const growthRates = yearsData
    .filter((item) => item.value > 0) // Filtra os itens com valor maior que 0
    .slice(0, Number(qdt_year))
    .map((data, index) => {
      if (index === 0) return { year: data.year, growth: null }; // Sem crescimento para o primeiro ano
      const prevValue = yearsData[index - 1].value;
      const growth = ((data.value - prevValue) / prevValue) * 100;
      return { year: data.year, growth: growth.toFixed(0) }; // Formatado com 2 casas decimais
    });

  const configPorcentagemBottomUP = {
    data: valoresProdutos,
    angleField: "total_volume",
    colorField: "name",
    x: false,
    innerRadius: 0.6,
    height: 500,
    group: true,
    label: {
      text: ({ name, total_volume }: any) => {
        const totalVolume = valoresProdutos.reduce(
          (acc: any, item: any) => acc + item.total_volume,
          0
        );
        const percentage = (total_volume / totalVolume) * 100;
        return `${name}: ${percentage.toFixed(2)}%`; // Exibe a porcentagem com 2 casas decimais
      },
      style: {
        textAlign: (d: any) => (+d.total_volume > 790000.0 ? "right" : "start"),
        fill: (d: any) => (+d.total_volume > 790000.008 ? "#fff" : "#000"),
        dx: (d: any) => (+d.total_volume > 790000.0 ? -5 : 5),
      },
      position: "outside",
    },

    annotations: {
      text: ({ name, total_volume }: any) => {
        if (total_volume === 0) {
          return `${name}: 0%`; // Se o valor for 0, mostra 0%
        }
        return `${name}: ${total_volume.toFixed(2)}`; // Caso contrário, mostra o nome e o total_volume
      },
      fill: "#000000",
      style: {
        fontWeight: "bold",
        width: "90%",
      },

      tooltip: {
        title: ({ name, total_volume }: any) => {
          const formattedVolume = Math.round(total_volume * 100) / 100; // Round to two decimal places
          return `${name}: ${formattedVolume}`;
        },
      },
    },
    legend: true,
  };

  return (
    <div>
      <Row style={{ marginTop: 5, width: "100vw" }}>
        <Col span={24}>
          <>
            <main className="main-container">
              <div className="sales-projection-header">
                <Button
                  type="primary"
                  size="large"
                  onClick={() => LimparFiltros()}
                >
                  Limpar Filtros
                </Button>

                <div className="header-title">
                  <span>Projeção de Vendas - Filial e Produto</span>
                </div>
                <div>
                  <b style={{ color: "#fff" }}>Atualizar: </b>
                  <Select
                    defaultValue="0"
                    style={{ width: 120 }}
                    value={timeAtualizarTelaAuto}
                    onChange={(e) => {
                      setTimeAtualizarTelaAuto(e);
                    }}
                  >
                    <Option value="0">Não</Option>
                    <Option value="5">5 Sec</Option>
                    <Option value="10">10 Sec</Option>
                    <Option value="20">20 Sec</Option>
                  </Select>
                </div>
              </div>
            </main>
            <div className="report-container">
              <Row>
                <Col style={{ width: "100%" }} span={24}>
                  <div
                    className="report-grid"
                    style={{
                      flexWrap: "nowrap",
                      gap: "30px",
                    }}
                  >
                    {years?.map((item: string, index: number) => {
                      // Determina o título com base no ano
                      const title =
                        item === "year0"
                          ? year0 + " R$"
                          : item === "year1"
                          ? year1 + " R$"
                          : item === "year2"
                          ? year2 + " R$"
                          : item === "year3"
                          ? year3 + " R$"
                          : item === "year4"
                          ? year4 + " R$"
                          : item === "year5"
                          ? year5 + " R$"
                          : null;

                      // Determina o valor com base no ano
                      const value =
                        item === "year0"
                          ? somaAno0
                          : item === "year1"
                          ? somaAno1
                          : item === "year2"
                          ? somaAno2
                          : item === "year3"
                          ? somaAno3
                          : item === "year4"
                          ? somaAno4
                          : item === "year5"
                          ? somaAno5
                          : null;

                      // Não renderiza o card se o título for `null`
                      return title && value !== null ? (
                        <Card
                          key={index}
                          title={title}
                          bordered={true}
                          className="card-statistic"
                        >
                          <div className="report-card-content">
                            <Statistic
                              value={value.toLocaleString("pt-BR")}
                              valueStyle={{ color: "#3f8600" }}
                            />
                          </div>
                        </Card>
                      ) : null;
                    })}

                    {growthRates.map((item: any, index: any) =>
                      item.growth != null ? (
                        <Card
                          key={index}
                          title={"Crescimento em " + item.year + " %"}
                          bordered={true}
                          style={{
                            margin: "5px",
                          }}
                        >
                          <div className="report-card-content">
                            <Statistic
                              value={item.growth + "%"}
                              valueStyle={{ color: "#ff0000" }}
                            />
                          </div>
                        </Card>
                      ) : null
                    )}
                  </div>
                </Col>

                {timeAtualizarTelaAuto !== "0" && (
                  <div className="divtimer">
                    <div className="timer">Atualiza em {contador} segundos</div>
                  </div>
                )}
              </Row>

              <Row>
                <Col span={3}>
                  <Card title="Ano" bordered={false} className="card-filter">
                    <div className="report-card-content">
                      <Select
                        mode="multiple"
                        placeholder="Please select"
                        onChange={handleChange}
                        value={years}
                        style={{ width: "100%" }}
                        options={yearsOptions(Number(qdt_year)).map(
                          (year: string, index: number) => ({
                            value: year,
                            label: eval(`year${index}`),
                          })
                        )}
                      />
                    </div>
                  </Card>
                  <Card
                    title="Regional"
                    bordered={false}
                    className="card-filter"
                  >
                    <div className="report-card-content">
                      <Select
                        placeholder="Regional"
                        defaultValue={{
                          label: "Todos",
                          value: 0,
                        }}
                        onChange={handleRegionChange}
                        value={filterRegional}
                        style={{ width: "100%" }}
                        options={[
                          { value: 0, label: "Todos" }, // Adiciona a opção "Todos"
                          ...OptionsRegional.map((item: any) => ({
                            value: item.id,
                            label: item.name,
                          })),
                        ]}
                      />
                    </div>
                  </Card>
                  <Card bordered={false} className="card-filter">
                    <div className="report-card-content">
                      <Button
                        disabled={years.length < 2}
                        type="primary"
                        onClick={() => [
                          handleFilter(),
                          setTimeAtualizarTelaAuto("0"),
                          setFilterFilialModal(0),
                        ]}
                      >
                        Filtrar
                      </Button>
                    </div>
                  </Card>
                </Col>
                <Col span={21} style={{ marginTop: 5 }}>
                  <div className="report-grid-simple">
                    <Card
                      title={
                        <>
                          <strong>Regional</strong>{" "}
                          {/* Adiciona "Regional" antes do título */}
                          {yearsData
                            .filter((item) => item.value >= 0)
                            .slice(1, Number(qdt_year))
                            .map((item: any, index: any, array: any[]) => (
                              <b key={index}>
                                {/* {item.year}
                                {index < array.length - 1 ? "YTD vs" : "P"}{" "} */}
                                {/* "YTD vs" para os anteriores, "P" para o último */}
                              </b>
                            ))}
                        </>
                      }
                      bordered={true}
                      className="chart-card"
                    >
                      <div className="iframe-container">
                        <Column {...configRegional} />
                      </div>
                    </Card>

                    <Card
                      title={
                        <>
                          <strong>Filial</strong>{" "}
                          {/* Adiciona "Regional" antes do título */}
                          {yearsData
                            .filter((item) => item.value >= 0)
                            .slice(1, Number(qdt_year))
                            .map((item: any, index: any, array: any[]) => (
                              <b key={index}>
                                {/* {item.year}
                                {index < array.length - 1 ? "YTD vs" : "P"}{" "} */}
                                {/* "YTD vs" para os anteriores, "P" para o último */}
                              </b>
                            ))}
                        </>
                      }
                      extra={
                        <>
                          <b style={{ marginRight: "10px", color: "#FFF" }}>
                            {DataFilial.find(
                              (item: any) => item.id === filterFilialModal
                            )?.name || "Todos"}
                          </b>

                          <Button
                            type="primary"
                            icon={<FilterOutlined />}
                            onClick={() => open()}
                          />
                        </>
                      }
                      bordered={true}
                      className="chart-card"
                    >
                      <div className="iframe-container">
                        <Bar {...configFilal} />
                      </div>
                    </Card>

                    <Card
                      title={
                        /* {item.year}
                            {index < array.length - 1 ? " vs" : ""}{" "} */
                        "2025/26"
                        /* Adiciona "vs" apenas se não for o último */
                      }
                      bordered={true}
                      className="chart-card"
                    >
                      <div className="chart-container">
                        <Bar {...configPorcentagemBottomUP} />
                      </div>
                    </Card>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        </Col>
      </Row>

      <Modal
        title="Fitrar Por Filial"
        open={isModalVisible}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        okText="Filtrar"
        cancelText="Fechar"
      >
        <Select
          placeholder="Regional"
          defaultValue={{
            label: "Todos",
            value: 0,
          }}
          onChange={handleFilialChangeModal}
          value={filterFilialModal}
          style={{ width: "100%" }}
          options={[
            { value: 0, label: "Todos" },
            ...filialOptions.map((item: any) => ({
              label: item.name,
              value: item.id,
            })),
          ]}
        />
      </Modal>
    </div>
  );
}
