import { message } from "antd";
import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from "axios";

const url = window.location.origin;

declare module "axios" {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

abstract class Http {
  protected readonly instance: AxiosInstance;

  public constructor() {
    const config = {
      baseURL:
        url === "http://localhost:3000"
          ? process.env.REACT_APP_API_URL
          : "backendcropland",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=utf-8",
      },
      timeout: 300000,
    };

    this.instance = axios.create(config);
    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    );
  };

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest as any,
      this._handleError
    );
  };

  private _handleRequest = (config: AxiosRequestConfig) => {
    const token = window.localStorage.token;

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    return config;
  };

  private _handleResponse = ({ data }: AxiosResponse) => data;

  protected _handleError = (error: any) => {
    if (error.response.status === 403) {
      localStorage.clear();
      window.location.href = "/";
    }
    console.log(error);
    if (error.response.data.error.name === "SequelizeUniqueConstraintError") {
      message.error("Dados já cadastrados");
    }
    return Promise.reject(error);
  };
}

export default Http;
