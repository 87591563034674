import Http from "../../../utils/http";

class BottomUpService extends Http {
  public constructor() {
    super();
  }

  public getTableProduct = (id: number, level3_id: number) =>
    this.instance.get(`/company/${id}/pgv/bottom-up?level3_id=${level3_id}`);


  public getTableProducttodos = (id: number) =>
    this.instance.get(`/company/${id}/pgv/bottom-up/todos`);


  public searchTableProduct = (id: number, level3_id: number, search: string) =>
    this.instance.get(
      `/company/${id}/pgv/bottom-up?level3_id=${level3_id}&filter_text=${search}`
    );

  public seveTableData = (id: number, body: object) =>
    this.instance.post(`/company/${id}/pgv/bottom-up`, body);

  public putBottomUpStatus = (id: number, body: object) =>
    this.instance.put(`/company/${id}/pgv/bottom-up/status`, body);

  public getMiniDashboard = (id: number, level3_id: number) =>
    this.instance.get(
      `/company/${id}/pgv/bottom-up/mini-dash?level3_id=${level3_id}`
    );
}

export default new BottomUpService();
