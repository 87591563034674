import Http from "../../../utils/http";

class PowerBi_all extends Http {
  public constructor() {
    super();
  }

  public getPowerBi = async (companyId: number) => {
    return this.instance.get(`/company/${companyId}/parameter/type/3`);
  };

  public getButtomUpFindRegional = async (companyId: number) => {
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/regional/find`
    );
  };

  public GetButtomUpRegional = async (
    companyId: number,
    years: string[],
    filterRegional: number
  ) => {
    const yearsParam = years.join(",");
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/regional`,
      {
        params: { years: yearsParam, filterRegional: filterRegional },
      }
    );
  };

  public GetButtomUpFilial = async (
    companyId: number,
    years: string[],
    filterRegional: number,
    filterFilial?: number
  ) => {
    const yearsParam = years.join(",");
    return this.instance.get(
      `/company/${companyId}/pgv/bottom-up/dashboard/filial`,
      {
        params: { years: yearsParam, filterRegional: filterRegional, filterFilial: filterFilial },
      }
    );
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PowerBi_all();
