import { Avatar, Button, Dropdown, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import {
  FiBell,
  FiUser,
  FiChevronDown,
  FiLogOut,
  FiMaximize2,
} from "react-icons/fi";

import { Logo, Container, Notification, Account } from "./styles";

import logo from "../../../assets/logo.svg";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { logout } from "../../../modules/Auth/Reducer";
import { persistor } from "../../../store";
 import { PowerBi_Dashboard } from "../../../modules/PowerBi";
 import PowerBi_all from "../../../services/Global/PowerBi";
import { useEffect, useState } from "react";
import { decrement } from "../../../modules/Parameters/Reducer";
export function Header() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { name } = useAppSelector((state) => state.auth.user);
  const companyName = useAppSelector((state) => state.parameters.company.name);
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const companyCnpj = useAppSelector(
    (state) => state.parameters.company.registered_number
  );
  const companyLogo = useAppSelector((state) => state.parameters.company.logo);
 

  const logoutRedirect = () => {
    dispatch(decrement());
    dispatch(logout());
    persistor.purge();
    navigate("/");
  };

  const menu = (
    <Menu>
      <Menu.Item
        icon={<FiMaximize2 />}
        key="company"
        onClick={() => navigate("/")}
      >
        Trocar empresa
      </Menu.Item>
      <Menu.Item icon={<FiLogOut />} key="logout" onClick={logoutRedirect}>
        Sair
      </Menu.Item>
    </Menu>
  );

  return (
    <Container>
      <Logo
        src={logo}
        alt="Logo Cropland"
        onClick={() => navigate("/select-module")}
      />
      <div style={{ display: "flex", marginRight: "-55%" }}>
        <div style={{ marginTop: 10 }}>
          {companyName !== undefined ? (
            <>
              <Avatar
                src={companyLogo}
                alt={"Logo " + companyName}
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 5,
                }}
              />
            </>
          ) : (
            <></>
          )}
        </div>
        <div style={{ display: "block", userSelect: "none" }}>
          <div>{companyName}</div>
          <div style={{ marginTop: -45 }}>
            {companyCnpj?.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1$2$3/$4-$5"
            )}
          </div>
        </div>
      </div>
      {companyId? (
        <Button
          type="primary"
          style={{ position: "absolute", left: 250 }}
          onClick={() => navigate("/Dashboard")}
        >
          <img
            width={40}
            style={{ marginLeft: "-20px", display: "none" }}
            src="https://1000logos.net/wp-content/uploads/2022/08/Microsoft-Power-BI-Logo.png"
            alt="Dashboard"
          />
          Dashboard
        </Button>
      ) : (
        <></>
      )}

      <Notification>
        <FiBell style={{ marginRight: 10 }} size={18} />
        <Dropdown overlay={menu}>
          <Account>
            <Avatar icon={<FiUser size={16} />} />
            {name}
            <FiChevronDown />
          </Account>
        </Dropdown>
      </Notification>
    </Container>
  );
}
