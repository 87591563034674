/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-eval */
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  message,
  Row,
  Drawer,
  Tree,
} from "antd";
import { useAppSelector } from "../../hooks";
import { useState, useEffect } from "react";
import PowerBi_all from "../../services/Global/PowerBi";
import { LeftOutlined } from "@ant-design/icons";
import { FiMenu } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { BottomUPDashboard } from "./BottomUp/index";
import { ActionPlanDashboard } from "./PGV/ActionPlan/index";
import { ActionPlanDashboardPGC } from "./PGC/ActionPlan";
import "./index.css";

export function PowerBi_Dashboard() {
  const navigate = useNavigate();
  const companyId = useAppSelector((state) => state.parameters.company.id);
  const parameters = useAppSelector((state) => state.entities.parameter);
  const [data, setData] = useState<any>([]);
  const [url, setUrl] = useState<any>(undefined);
  const [Menu, setMenu] = useState<boolean>(true);
  const [selected, setSelected] = useState<string | null>(null);
  useEffect(() => {
    if (companyId) loadLinks();
  }, [companyId]);

  async function loadLinks() {
    const response = await PowerBi_all.getPowerBi(companyId);
    setData(response);
  }
  function abiriRelaroio_Iframe(url: any) {
    selectDashboard("");
    if (url.length > 50) {
      setMenu(false);
      setUrl(url);
    } else {
      setUrl(undefined);
      message.warning("Relatório está indisponível no momento!");
    }
  }

  function selectDashboard(Name: string) {
    setMenu(false);
    setSelected(Name);
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            headerBg: "#23d978",
          },
          Table: {
            headerBg: "#23d978",
            footerBg: "#23d978",
          },
        },
      }}
    >
      <div>
        <Row className="row-container">
          <Col className="col-container">
            <Button
              className="button-container"
              onClick={() => navigate("/select-module")}
            >
              <LeftOutlined className="icon-style" />
            </Button>
          </Col>
          <span className="span-text">DashBoard</span>
        </Row>

        <Row style={{ marginTop: 5 }}>
          <Card className="menu-toggle-card" onClick={() => setMenu(!Menu)}>
            <FiMenu color="#00CC66" size={20} />
          </Card>
          <Drawer
            title="Menu"
            placement="left"
            closable={false}
            onClose={() => setMenu(!Menu)}
            open={Menu}
            key={"left"}
          >
            <Col span={24}>
              {data.length > 0 ? (
                <Card bordered={true} className="card-container">
                  {data.map(
                    (item: { id: number; name: string; value: string }) => (
                      <Button
                        key={item.id}
                        type="primary"
                        onClick={() => abiriRelaroio_Iframe(item.value)}
                        className="button-style"
                      >
                        {item.name}
                      </Button>
                    )
                  )}
                </Card>
              ) : (
                <div>
                  <Card size="small" bordered={false}>
                    <Button
                      key={2}
                      type="primary"
                      className="button-style"
                      onClick={() => selectDashboard("BOTTOM_UP")}
                    >
                      <span>Projeções de Vendas Filial e Produto</span>
                    </Button>
                  </Card>

                   <Card size="small" bordered={false}>
                    
                    <Button
                      key={3}
                      type="primary"
                      className="button-style"
                      onClick={() => selectDashboard("PLANO_DE_ACAO_PGV")}
                    >
                      <span>Plano de Açõe - PGV</span>
                    </Button>
                   
                  </Card> 

                  <Card size="small" bordered={false}>
                    <Button
                      key={4}
                      type="primary"
                      className="button-style"
                      onClick={() => selectDashboard("PLANO_DE_ACAO_PGC")}
                    >
                      <span>Plano de Açõe - PGC</span>
                    </Button>
                  </Card>
                </div>
              )}
            </Col>
          </Drawer>

          {url !== undefined ? (
            <Col span={24}>
              <>
                <div className="iframe-container">
                  <iframe
                    title="% Faturamento por Tipo Acesso"
                    className="iframe-style"
                    width="100%"
                    allowFullScreen={true}
                    src={url}
                  />
                </div>
              </>
            </Col>
          ) : selected === "PLANO_DE_ACAO_PGV" ? (
            <ActionPlanDashboard />
          ) : selected === "PLANO_DE_ACAO_PGC" ? (
            <ActionPlanDashboardPGC />
          ) : selected === "BOTTOM_UP" ? (
            <BottomUPDashboard />
          ) : null}
        </Row>
      </div>
    </ConfigProvider>
  );
}
